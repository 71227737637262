import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const state = () => ({
  isSandbox: false,
  sandboxUrl: null,
});

const actions = {};

const getters = {
  getSandboxUrl: state => state.sandboxUrl,
  isSandbox: state => state.isSandbox,
};

const mutations = {
  setIsSandBox(state, payload){
    state.isSandbox = payload
  },
  setSandboxUrl(state, payload){
    state.sandboxUrl = payload
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
